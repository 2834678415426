import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { CustomPageDTO } from '@/model/CustomPageDTO'


export interface IMarkupRoute {
    routeData: CustomPageDTO[];
    markupData: CustomPageDTO[];
    editingPage: CustomPageDTO;
}

@Module({ dynamic: true, store, namespaced: true, name: 'markuproutemodule' })
class MarkupRoute extends VuexModule implements IMarkupRoute {
    routeData: CustomPageDTO[] = [];
    markupData: CustomPageDTO[] = [];
    editingPage: CustomPageDTO = {} as CustomPageDTO

    @Mutation
    setRouteData(newData: CustomPageDTO[]) {
        this.routeData = newData
    }

    @Mutation
    setMarkupData(newData: CustomPageDTO[]) {
        this.markupData = newData;
    }

    @Mutation
    setEditing(newData: CustomPageDTO) {
        this.editingPage = newData
    }

    @Mutation
    updatePage(page: CustomPageDTO): void {
        const id = page.customPageID
        const newMarkup = page.content
        let index = this.routeData.findIndex(x => x.customPageID === id)
        if (index > -1) {
            this.routeData[index].content = newMarkup
        }

        index = this.markupData.findIndex(x => x.customPageID === id)
        if (index > -1) {
            this.markupData[index].content = newMarkup
        }
    }

    @Mutation
    addPage(input: CustomPageDTO): void {
        this.routeData.push(input)
        this.markupData.push(input)
    }

}

export const MarkupRouteModule = getModule(MarkupRoute)