import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import '@/styles/App.scss'
import axios from 'axios'
import { Core } from "@grapecity/activereports";
import { MarkupRouteModule } from './store/modules/MarkupRouteModule'
import config from 'devextreme/core/config'; 

Core.setLicenseKey(process.env.VUE_APP_ARJS_KEY!);
config({ licenseKey: process.env.VUE_APP_DEVEXTREME_KEY! }); 

axios.defaults.baseURL = process.env.BASE_URL


axios.get('api/CustomPages/GetActiveCustomPages').then((response) => {
    MarkupRouteModule.setRouteData(response.data)

    MarkupRouteModule.routeData.forEach(data => {
        if (data.isCustomPage && data.enabled && (!data.route.includes('photodownloads') && !data.route.includes('logodownloads'))) {
            router.addRoute({
                path: `${data.route.trim()}`,
                component: () => import(/* webpackChunkName: "markupview" */ "@/views/MarkupView.vue"),
                name: data.title,
                meta: { title: data.title, authorize: ["Portal-Customer", "IT"] }
            });
        }
    });
}).then(() => {
    const app = createApp(App)
    app.config.errorHandler = (err, vm, info) => {
        if (err instanceof Error) {
            console.log(err.name)
            console.log(err.message)
            console.log(err.stack)  
        } else {
            console.error("Error:", err);
            console.error("Vue component:", vm);
            console.error("Additional info:", info);
        }
    }
    app.use(store).use(router).mount('#app')
}).catch((error) => {
    console.log(error)
    const app = createApp(App)
    app.config.errorHandler = (err, vm, info) => {
        if (err instanceof Error) {
            console.log(err.name)
            console.log(err.message)
            console.log(err.stack)
        } else {
            console.error("Error:", err);
            console.error("Vue component:", vm);
            console.error("Additional info:", info);
        }
    }
    app.use(store).use(router).mount('#app')
})
//const app = createApp(App).use(store).use(router).mount('#app')
