import { ref, reactive } from 'vue'
import { Auth } from '@/services/MsalService'
import type { MaybeAccount } from '@/services/MsalService'
import type { NavigationClient } from '@azure/msal-browser'

export const initialized = ref(false)
export const account = ref<MaybeAccount>(null)
export const error = ref<string>()

async function initialize(client?: NavigationClient) {
    if (initialized.value === true) {
        return account.value
    }
    return Auth.initialize(client).then(data => {
        account.value = data
        initialized.value = true
        return data
    })
}

async function login() {
    error.value = ''
    return Auth.login()
        .then(data => {
            account.value = data
            error.value = ''
            return account.value
        })
        .catch(err => {
            error.value = err.message
            throw (err)
        })
}

async function loginRedirect() {
    error.value = ''
    return Auth.loginRedirect()
        .then(data => {
            account.value = data
            error.value = ''
            return account.value
        })
        .catch(err => {
            error.value = err.message
            throw (err)
        })
}

async function loginSilent() {
    error.value = ''
    return Auth.loginSilent()
        .then(data => {
            account.value = data
            error.value = ''
            return account.value
        })
        .catch(err => {
            error.value = err.message
            console.log(err)
            return null
        })
}

async function loginSSO() {
    error.value = ''
    return Auth.loginSSO()
        .then(data => {
            account.value = data
            error.value = ''
            return account.value
        })
        .catch(err => {
            error.value = err.message
            throw (err)
        })
}

async function logout() {
    return Auth.logout().then(() => {
        account.value = null
        return account.value
    })
}

async function logoutRedirect() {
    return Auth.logoutRedirect().then(() => {
        account.value = null
        return account.value
    })
}

async function changePassword() {
    error.value = ''
    return Auth.changePassword()
        .then(data => {
            account.value = data
            error.value = ''
            return account.value
        })
        .catch(err => {
            error.value = err.message
            throw (err)
        })
}


async function changePasswordRedirect() {
    error.value = ''
    return Auth.changePasswordRedirect()
        .then(data => {
            account.value = data
            error.value = ''
            return account.value
        })
        .catch(err => {
            error.value = err.message
            throw (err)
        })
}

async function resetCache() {
    Auth.reset()
}

export const auth = reactive({
    error,
    account,
    initialized,
    initialize,
    login,
    loginRedirect,
    loginSilent,
    loginSSO,
    logout,
    logoutRedirect,
    changePassword,
    changePasswordRedirect,
    resetCache,
})